<template>
  <div>
    <step-header/>
    <!--jimir3x-->
    <div
      class="step-content step-content--register-steps__step-4 step-content--register-steps__step-4--extra"
    >
      <!--jimir3x-->
      <loading :loading="loading" />
      <div class="container zoom">
        <div class="row">
          <div class="col-6">
            <div class="card bx-none">
              <div class="card-body">
                <div class="mt-3 mb-5">
                  <span style="line-height: 28px;
                               font-size: 18px;
                               letter-spacing: 0.02em;
                               color: #3a3a3a;
                               font-weight: 100;"
                               >
                               {{ $t('auth.step4.title') }}
                               
                  </span>
                </div>

                <ul style="display: inline-block" class="pl-0">
                  <li
                    style="display: inline-block"
                    v-for="(item, index) in keywords"
                    :key="index"
                    class="mr-3 before-added-keyword"
                  >
                    #{{ item }}
                  </li>
                </ul>
                <hr class="mb-5" />

                <h4 class="mb-5" style="font-size: 18px">
                  {{ $t('auth.step4.title-2') }}
                </h4>

                <div class="row">
                  <div class="col-md-12">
                    <ul style="display: inline-block" class="pl-0">
                      <li
                        style="display: inline-block"
                        v-for="(item, index) in suggestions"
                        :key="index"
                        class="mr-3 before-added-keyword"
                      >
                        <a @click="addKeyword(index)" href="javascript:void(0)">
                          #{{ item }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 donut-compatibly text-center pt-5">
            <h4>{{ $t('auth.step4.y-comprate') }}</h4>
            <vc-donut
              :sections="sections"
              :start-angle="chartValue"
              :thickness="10"
              class="chart-type donut-chart"
            >
              <h5>{{ rate }} %</h5>
              <h6>{{ $t('auth.step4.comprate') }}</h6>
            </vc-donut>
            <a href="">{{ $t('buttons.learn') }}</a>
          </div>
        </div>
      </div>
    </div>
    <step-footer v-if="!loading" :disabled="$_.size(keywords) < 3" :skipable="true" @skip="skip()" @submit="formSubmit" />
  </div>
</template>

<script>
import stepFooter from '../../components/register-step/step-footer';
import addTags from '../../graphql/me/addTags.gql';
import getProducts from '@/graphql/me/getProducts.gql';
import getInquiries from '@/graphql/me/getInquiries.gql';
import Loading from '@/components/global/loading';
import updateOnboarding from '../../graphql/me/updateOnboarding.gql';
import compatibilityRate from '../../graphql/me/profile/compatibilityRate.graphql';
import myKeywords from '../../graphql/me/profile/keywords.gql';
import _ from 'lodash';
import StepHeader from '@/components/register-step/step-header.vue';
export default {
  components: {
    stepFooter,
    Loading,
    StepHeader,
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      delay: null,
      inputTag: null,
      emptyTag: false,
      resultTagList: [],
      showDrop: false,
      sections: [
        {
          value: '',
          color: '#198CFF',
        },
      ],
      rate: 0,
      chartValue: 90,
      keywords: [],
      suggestions: [],
      industries: [],
    };
  },
  methods: {
    async skip() {
        await this.$apollo.mutate(updateOnboarding);
        await this.$store.dispatch(
            'checkToken', 
            { 
                vm: this, 
                par: { token: localStorage.getItem('conToken') }, 
                refresh: true 
            }
        );
        this.$router.push('/landing');
    },
    async formSubmit(index) {
      if (_.size(this.keywords) === 0) {
        let par = {
          title: this.$t('General.error'),
          message: this.$t('buttons.addtag'),
          type: 'danger',
          hide: true,
        };
        return this.makeToast(par);
      }

      this.loading = true;
      let data = await this.$apollo.mutate(addTags, { tags: this.keywords });
      let response = data('**.tags');
      if (response) {
        this.submit = true;
        await this.$apollo.mutate(updateOnboarding);
        await this.$store.dispatch(
            'checkToken', 
            { 
                vm: this, 
                par: { token: localStorage.getItem('conToken') }, 
                refresh: true 
            }
        );
        this.$router.push('/landing');
      }
      this.loading = false;
    },
    async generateMyKeywords() {
      let params = {
        limit: 10,
        filters: {},
      };

      let data = await this.$apollo.query(getProducts, params);
      let response = data('**.list.list');

      let data2 = await this.$apollo.query(getInquiries, params);
      let response2 = data2('**.list.list');
      let keywords = [];

      keywords = _.concat(
        keywords,
        _.map(response, x => x.contents.find(a => a.default).name)
      );
      keywords = _.concat(
        keywords,
        _.map(response, x => x.industry.name)
      );
      keywords = _.concat(
        keywords,
        _.map(response2, x => x.contents.find(a => a.default).name)
      );
      keywords = _.concat(
        keywords,
        _.map(response2, x => x.industry.name)
      );
      keywords.push(this.GET_USER_INFO.industry.name);
      keywords.push(this.GET_USER_INFO.title.name);

      this.industries = _.concat(
        this.industries,
        _.map(response, x => x.industry.name)
      );
      this.industries = _.concat(
        this.industries,
        _.map(response2, x => x.industry.name)
      );
      this.industries.push(this.GET_USER_INFO.industry.name);
      this.industries = _.uniq(this.industries);

      if (this.GET_USER_INFO.position) {
        keywords.push(this.GET_USER_INFO.position.name);
      }

      this.keywords = _.uniq(keywords);
    },
    async compatibilityRate() {
      let data = await this.$apollo.query(compatibilityRate, {
        tags: this.keywords,
      });
      let response = data('**.compatibility');
      this.rate = response;
      this.sections[0].value = this.rate;
    },

    async fetchSuggestions() {
      let data = await this.$apollo.query(myKeywords, {
        tags: this.industries,
      });
      let suggestions = data('**.suggestions');
      suggestions = _.filter(
        suggestions,
        s =>
          _.findIndex(this.keywords, k => _.lowerCase(k) === _.lowerCase(s)) ===
          -1
      );
      this.$set(this, 'suggestions', suggestions);
    },

    addKeyword(index) {
      this.keywords.push(this.suggestions[index]);
      this.suggestions.splice(index, 1);
      this.compatibilityRate();
    },
  },
  async created() {
    await this.generateMyKeywords();
    await this.compatibilityRate();
    await this.fetchSuggestions();
  },
};
</script>
